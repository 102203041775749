import { useEffect, useState } from 'react';
import axiosInstance from "./axiosConfig";
import { Link } from 'react-router-dom';
import { FaLessThan, FaEdit, FaTrashAlt } from 'react-icons/fa';  // Import icons
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 

const Department = () => {
  const [departmentId, setDepartmentId] = useState(''); 
  const [departmentName, setDepartmentName] = useState('');
  const [departmentHead, setDepartmentHead] = useState('');
  const [departments, setDepartments] = useState([]);
  const [editingDepartmentId, setEditingDepartmentId] = useState(null);  // New state to track the department being edited

  useEffect(() => {
    const fetchDepartment = async () => {
        try {
            const response = await axiosInstance.get(`hrmsapplication/department/getAllDepartments`);
            const data = response.data; 
            setDepartments(data);
            console.log("Fetched data:", data);
        } catch (error) {
            console.error('Error fetching department details:', error);
            toast.error("Error fetching departments.");
        }
    };
    fetchDepartment();  
  }, []);

  const handleAddDepartment = async () => {
    if (!departmentId.trim() || !departmentName.trim() || !departmentHead.trim()) {
      alert('Please enter department ID, name, and head.');
      return;
    }

    const departmentData = {
      departmentId,
      departmentName,
      departmentHead,
    };

    try {
      const response = await axiosInstance.post(
        '/hrmsapplication/department/create',
        departmentData
      );

      const result = response.data;
      setDepartments([...departments, departmentData]);
      setDepartmentId('');
      setDepartmentName('');
      setDepartmentHead('');
      toast.success("Department added successfully!"); 
      console.log('Department added:', result);
    } catch (error) {
      console.error('Error adding department:', error);
      toast.error("Error adding department. Please try again.");
    }
  };

  // PATCH Request (Update Department)
  const handleUpdateDepartment = async () => {
    if (!departmentName.trim() || !departmentHead.trim()) {
      alert('Please enter department name and head.');
      return;
    }

    const updatedDepartmentData = {
      departmentName,
      departmentHead,
    };

    try {
      const response = await axiosInstance.patch(
        `hrmsapplication/department/update`,
        { departmentId: editingDepartmentId, ...updatedDepartmentData }
      );

      const result = response.data;
      setDepartments(departments.map((dept) =>
        dept.departmentId === editingDepartmentId ? { ...dept, ...updatedDepartmentData } : dept
      ));
      setDepartmentName('');
      setDepartmentHead('');
      setDepartmentId('');
      setEditingDepartmentId(null); // Reset editing state after update
      toast.success("Department updated successfully!");
      console.log('Department updated:', result);
    } catch (error) {
      console.error('Error updating department:', error);
      toast.error("Error updating department. Please try again.");
    }
  };

  // DELETE Request (Delete Department by ID)
  const handleDeleteDepartment = async (departmentId) => {
    if (window.confirm('Are you sure you want to delete this department?')) {
      try {
        const response = await axiosInstance.delete(
          `hrmsapplication/department/deleteDepartment/${departmentId}`
        );

        const result = response.data;
        setDepartments(departments.filter((dept) => dept.departmentId !== departmentId));
        toast.success("Department deleted successfully!");
        console.log('Department deleted:', result);
      } catch (error) {
        console.error('Error deleting department:', error);
        toast.error("Error deleting department. Please try again.");
      }
    }
  };

  // Set the department fields to edit
  const handleEditDepartment = (department) => {
    setEditingDepartmentId(department.departmentId);  // Track the department being edited
    setDepartmentId(department.departmentId);
    setDepartmentName(department.departmentName);
    setDepartmentHead(department.departmentHead);
  };

  return (
    <div>
      <div className="flex flex-wrap items-center justify-start px-1 py-1 border-2 border-gray-800 rounded-md w-[150px] mb-3 mt-5 ml-5">
        <FaLessThan className="text-orange-400 mr-2" />
        <Link to={`/AdminDashboard`}>
          <button>
            <span className="text font-semibold text-orange-400">Previous Page</span>
          </button>
        </Link>
      </div>

      <div className="mb-6 shadow-md p-4 rounded-lg">
        <h2 className="text-xl text-center mb-4 font-bold p-1 text-white bg-purple-700 rounded-lg">Manage Department</h2>
        
        <input
          type="text"
          className="w-full px-4 py-2 mb-4 border rounded-lg"
          placeholder="Enter department ID"
          value={departmentId}
          onChange={(e) => setDepartmentId(e.target.value)}
          disabled={editingDepartmentId}  // Disable ID input while editing
        />
        <input
          type="text"
          className="w-full px-4 py-2 mb-4 border rounded-lg"
          placeholder="Enter department name"
          value={departmentName}
          onChange={(e) => setDepartmentName(e.target.value)}
        />
        <input
          type="text"
          className="w-full px-4 py-2 mb-4 border rounded-lg"
          placeholder="Enter department head"
          value={departmentHead}
          onChange={(e) => setDepartmentHead(e.target.value)}
        />
       
       <div  >
        <button
          onClick={editingDepartmentId ? handleUpdateDepartment : handleAddDepartment} // Toggle between update and add
          className="bg-purple-600 text-white px-6 py-2 rounded-lg hover:bg-purple-700 transition duration-300"
        >
          {editingDepartmentId ? 'Update Department' : 'Add Department'} 
        </button>
       
        <select
          className='ml-5  px-4 py-2 rounded-lg bg-purple-600 text-white'
          value={departmentName}
          //placeholder="department"
          //onChange={(e) => setDepartmentName(e.target.value)}
        >
          <option  value=""> All Departments </option>
          
          {departments.length > 0 ? (departments.map((dept) => (
            <option className='bg-white text-black' key={dept.departmentId} value={dept.departmentName}>
              {dept.departmentName}
            </option>
          ))
         ) : (
          <option>Loading...</option>
        )}
        </select>
        
        </div>
      </div>

      <div className="shadow-md p-4 rounded-lg">
        <h3 className="text-xl text-center mb-4 font-bold p-1 text-white bg-purple-700 rounded-lg">Department List</h3>
        
        <ul className="list-disc pl-6">
          {departments.length > 0 ? (
            departments.map((dept, index) => (
              <li key={index} className="mb-2 text-gray-700">
                <strong>ID:</strong> {dept.departmentId} - <strong>Name:</strong> {dept.departmentName} - <strong>Head:</strong> {dept.departmentHead}
                
                {/* Edit Button with React Icon */}
                <button
                  onClick={() => handleEditDepartment(dept)}
                  className="ml-4 text-black p-2 rounded-lg"
                >
                  <FaEdit /> {/* React Icon for Edit */}
                </button>

                {/* Delete Button with React Icon */}
                <button
                  onClick={() => handleDeleteDepartment(dept.departmentId)}
                  className="ml-2 text-black p-2 rounded-lg"
                >
                  <FaTrashAlt /> {/* React Icon for Delete */}
                </button>
              </li>
            ))
          ) : (
            <p className="text-gray-500">No departments added yet.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Department;
