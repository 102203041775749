import React, { useState, useEffect } from "react";
import { FaEdit, FaLessThan } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import axiosInstance from "./axiosConfig";
import EditFamilyDetails from "./EditPersonalDetails";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';  

const PersonalInfo = () => {
  const { employeeId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [personalDetails, setPersonalDetails] = useState({
    prefix: "",
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    maritialStatus: "",
    dob: "",
    gender: "",
    fatherName: "",
    doj: "",
    bloodGroup: "",
  });

  const userRole = localStorage.getItem("UserRole");

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `hrmsapplication/employee/getEmployeeProfile/${employeeId}`
      );
      const data = response.data;

      setPersonalDetails({
        prefix: data.prefix || "",
        firstname: data.firstname || "",
        middlename: data.middlename || "",
        lastname: data.lastname || "",
        email: data.email || "",
        countryCode: data.countryCode || "",
        phoneNumber: data.phoneNumber || 0,
        maritialStatus: data.maritialStatus || "",
        dob: data.dob || "",
        gender: data.gender || "",
        fatherName: data.fatherName || "",
        doj: data.doj || "",
        bloodGroup: data.bloodGroup || "",
      });

      // toast.success("Data loaded successfully!"); // Show success toast for data fetch
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data!"); // Show error toast for data fetch failure
    }
  };

  useEffect(() => {
    fetchData();
  }, [employeeId]);

  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSave = async (updatedDetails) => {
    try {
      const dataToUpdate = {
        employeeId,
        ...updatedDetails,
      };

      const response = await axiosInstance.patch(
        "hrmsapplication/employee/update",
        dataToUpdate
      );

      console.log("Update successful:", response.data);

      fetchData();

      setIsModalOpen(false);
      toast.success("Personal details updated successfully!"); // Show success toast for update
    } 
    // catch (error) {
    //   console.log(error.response.data.toString());
    //   toast.error("kindly recheck the Form"); // Show error toast for update failure
    // }
    catch (error) {
      console.error("Error adding the job:", error);

      // Extract error details
      let errorMessage = "Failed to add the job. Please try again.";
      if (error.response?.data) {
        if (error.response.data.detail) {
          errorMessage = error.response.data.detail;
        } else if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }
      }

      toast.error(errorMessage); // Show extracted error in toast
    }
  };

  return (
    <div>
      {  /* ToastContainer to display toasts */}

      <div className="flex flex-wrap items-center justify-start px-1 py-1  border-2 border-gray-800 rounded-md w-[150px] mb-3 mt-5 ml-5 ">
        <FaLessThan className="text-orange-400 mr-2" />
        <Link to={`/dashboard/${employeeId}`}>
          <button>
            <span className="text font-semibold text-orange-400">
              Previous Page
            </span>
          </button>
        </Link>
      </div>

      <div className="flex justify-center items-start p-5 mt-16">
        <div className="w-2/3 mt-5 bg-white shadow-lg rounded-lg relative">
          <div className="bg-orange-500 text-white p-6 rounded-t-lg"></div>
          <div className="p-8 border border-gray-300 rounded-b-lg relative">
            <div className="absolute top-9 right-9 flex space-x-2">
              {userRole === "ROLE_ADMIN" && (
                <button
                  className="text-black-500 hover:text-orange-700"
                  onClick={handleEditClick}
                >
                  <FaEdit size={20} />
                </button>
              )}
            </div>

            <div className="bg-gray-100 p-5 rounded-md border border-gray-300">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div className="flex flex-col">
                  <p className="font-bold">Prefix</p>
                  <p className="break-words">{personalDetails.prefix}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">First Name</p>
                  <p className="break-words">{personalDetails.firstname}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Last Name</p>
                  <p className="break-words">{personalDetails.lastname}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Middle Name</p>
                  <p className="break-words">{personalDetails.middlename}</p>
                </div>

                <div className="flex flex-col">
                  <p className="font-bold">Email</p>
                  <p className="break-words">{personalDetails.email}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Phone Number</p>
                  <p className="break-words">
                    {personalDetails.countryCode} {personalDetails.phoneNumber}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Marital Status</p>
                  <p className="break-words">
                    {personalDetails.maritialStatus}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Date of Birth</p>
                  <p className="break-words">{personalDetails.dob}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Gender</p>
                  <p className="break-words">{personalDetails.gender}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Date of Joining</p>
                  <p className="break-words">{personalDetails.doj}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Father's Name</p>
                  <p className="break-words">{personalDetails.fatherName}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Blood Group</p>
                  <p className="break-words">{personalDetails.bloodGroup}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <EditFamilyDetails
            member={personalDetails}
            onSave={handleSave}
            onCancel={handleModalClose}
          />
        )}
      </div>
    </div>
  );
};

export default PersonalInfo;
