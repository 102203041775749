import React from 'react'

function OnboardingPersonalDetails() {
  return (<>
  <div>OnboardingPersonalDetails</div>
  
  </>
    
  )
}

export default OnboardingPersonalDetails