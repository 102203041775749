import React, { useState, useEffect } from "react";
import { AiOutlineHome, AiOutlineSearch } from "react-icons/ai";
import axiosInstance from "./axiosConfig";
import { useNavigate } from "react-router-dom";
import EditPersonalDetails from "./createEmply";
import { FaLessThan } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';

const EmployeeTable = () => {
  const [employees, setEmployees] = useState([]); // All employees fetched from the server
  const [filteredEmployees, setFilteredEmployees] = useState([]); // Employees after applying search
  const [searchQuery, setSearchQuery] = useState(""); // Search query
  const [pageNumber, setPageNumber] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(10); // Number of items per page
  const [showPopup, setShowPopup] = useState(false); // Whether to show the role-based popup
  const [showEditForm, setShowEditForm] = useState(false); // Whether to show the edit form
  const [userRole, setUserRole] = useState(""); // Selected user role for updating
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(""); // Selected employee id for role update

  const navigate = useNavigate();
  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get("/hrmsapplication/employee/getAll", {
        params: {
          pageNumber: 0,  // Always fetch from the first page to get all data
          size: 100000000, // Fetch a large number of employees (or adjust based on your backend capabilities)
        },
      });
      setEmployees(response.data);
      setFilteredEmployees(response.data); // Initially set filtered employees to all employees
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  useEffect(() => {
    fetchEmployees(); // Fetch employees on mount
  }, []);
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter the employees based on the search query
    const filtered = employees.filter(
      (employee) =>
        employee.employeeId.toString().toLowerCase().includes(query.toLowerCase()) ||
        employee.firstname.toLowerCase().includes(query.toLowerCase()) ||
        employee.email.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredEmployees(filtered); // Update filtered employees state
    setPageNumber(0); // Reset to first page on search
  };

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const handleCreateEmployeeClick = () => {
    setShowEditForm(true);
  };

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handleViewClick = (employeeId) => {
    navigate(`/dashboard/${employeeId}`);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleCancel = () => {
    setShowEditForm(false);
  };

  const handleSave = (formValues) => {
    console.log("Saved data:", formValues);
    setShowEditForm(false);
    fetchEmployees(); 
  };

  const handleConfirmRoleUpdate = async () => {
    const requestBody = {
      employeeId: selectedEmployeeId,
      userRole,
    };

    try {
      const response = await axiosInstance.patch(
        "/hrmsapplication/authentication/updateAuthenticationRole",
        requestBody
      );
      console.log("Role updated successfully:", response.data);
      setShowPopup(false);
      fetchEmployees(); 
    } catch (error) {
      console.error("Error updating role:", error);
    }
  };

  // Sort employees by employeeId numerically (extract numeric part from HRMS*)
  const sortEmployees = (employeesList) => {
    return employeesList.sort((a, b) => {
      const aId = parseInt(a.employeeId.replace(/\D/g, '')); // Remove non-numeric characters and parse as number
      const bId = parseInt(b.employeeId.replace(/\D/g, ''));
      return aId - bId;
    });
  };

  // Sorting and pagination logic
  const sortedEmployees = sortEmployees([...filteredEmployees]);

  const startIndex = pageNumber * pageSize;
  const endIndex = startIndex + pageSize;
  const currentEmployees = sortedEmployees.slice(startIndex, endIndex);

  return (
    <div className="container mx-auto p-6">
      <NavLink
        to='/admindashboard'
        className="flex items-center justify-start px-2 py-2 overflow-x-auto border-2 border-gray-800 rounded-md w-40 ml-5 mb-5 mt-5">
        <FaLessThan className="text-orange-400 mr-2" />
        <button>
          <span className="text font-semibold text-orange-400">Previous Page</span>
        </button>
      </NavLink>
      <nav className="flex items-center mb-4 p-2 border rounded-lg shadow-md w-30">
        <div className="flex items-center space-x-1">
          <AiOutlineHome className="text-xl" />
          <Link to='/AdminDashboard'><span className="text-lg font-bold">Home</span></Link>
        </div>
      </nav>

      <div className="flex justify-center items-center mb-4 space-x-4">
        <div className="flex items-right border rounded-lg">
          <input
            type="text"
            placeholder="Search..."
            className="py-2 px-3 focus:outline-none rounded-l-lg"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button className="bg-gray-200 text-gray-500 px-3 rounded-r-lg">
            <AiOutlineSearch />
          </button>
        </div>

        <button
          className="bg-gray-200 text-orange-400 font-semibold py-2 px-2 rounded"
          onClick={handleCreateEmployeeClick}
        >
          Create Employee
        </button>
        <button
          className="bg-gray-200 text-orange-400 font-semibold px-4 py-2 rounded"
          onClick={handleButtonClick}
        >
          Role Based
        </button>
      </div>

      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-3 px-4 border-b">Employee ID</th>
            <th className="py-2 px-4 border-b">Employee Name</th>
            <th className="py-2 px-4 border-b">Email ID</th>
            <th className="py-2 px-4 border-b">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentEmployees.length > 0 ? (
            currentEmployees.map((employee) => (
              <tr key={employee.employeeId} className="hover:bg-gray-100 text-center">
                <td className="py-2 px-4 border-b">{employee.employeeId}</td>
                <td className="py-2 px-4 border-b">{employee.firstname}</td>
                <td className="py-2 px-4 border-b">{employee.email}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    className="text-blue-500 hover:underline"
                    onClick={() => handleViewClick(employee.employeeId)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center py-4">No employees found</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="flex justify-between mt-4">
        <button
          className="bg-gray-200 text-orange-400 px-4 py-2 rounded"
          onClick={() => handlePageChange(pageNumber - 1)}
          disabled={pageNumber === 0}
        >
          Previous
        </button>
        <button
          className="bg-gray-200 text-orange-400 px-4 py-2 rounded"
          onClick={() => handlePageChange(pageNumber + 1)}
          disabled={endIndex >= filteredEmployees.length}
        >
          Next
        </button>
      </div>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Assign Role</h2>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                empId
              </label>
              <input
                type="text"
                className="w-full border border-gray-300 px-4 py-2 rounded"
                placeholder="Enter empId"
                onChange={(e) => setSelectedEmployeeId(e.target.value)}
              />
            </div>
            <div className="mb-5">
              <label className="block text-gray-700 font-semibold mb-2">
                Role
              </label>
              <div>
                <label className="inline-flex items-center mr-2">
                  <input
                    type="radio"
                    name="role"
                    value="ROLE_MANAGER"
                    className="form-radio"
                    onChange={(e) => setUserRole(e.target.value)}
                  />
                  <span className="ml-2">Manager</span>
                </label>
                <label className="inline-flex items-center mr-2">
                  <input
                    type="radio"
                    name="role"
                    value="ROLE_HR"
                    className="form-radio"
                    onChange={(e) => setUserRole(e.target.value)}
                  />
                  <span className="ml-2">HR</span>
                </label>
                <label className="inline-flex items-center mr-2">
                  <input
                    type="radio"
                    name="role"
                    value="ROLE_EMPLOYEE"
                    className="form-radio"
                    onChange={(e) => setUserRole(e.target.value)}
                  />
                  <span className="ml-2">Employee</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="role"
                    value="ROLE_ADMIN"
                    className="form-radio"
                    onChange={(e) => setUserRole(e.target.value)}
                  />
                  <span className="ml-2">Admin</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="role"
                    value="ROLE_ROOT"
                    className="form-radio"
                    onChange={(e) => setUserRole(e.target.value)}
                  />
                  <span className="ml-2">Root</span>
                </label>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleConfirmRoleUpdate}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showEditForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <EditPersonalDetails onCancel={handleCancel} onSave={handleSave} />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeTable;
